import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { appState } from '../../modals/basemodal';
import LoggedInUser from '../SignUp/LoggedInUser';
import Help from './Help';
import Logo from '../LogosAndIcons/Logo';

export const Navbar = observer(() => {
  return (
    <>
      <div className='absolute flex w-full bg-white h-14'>
        <div className='w-0 h-full my-2 lg:min-w-1/5 lg:w-1/5'>
          <Logo />
        </div>
        <div className='w-full h-full mt-0 leading-relaxed lg:mt-8 lg:w-3/5 lg:min-w-3/5'></div>
        <div className='flex justify-end w-0 h-full lg:min-w-1/5 lg:w-1/5'>
          <Help />
        </div>
      </div>
    </>
  );
});
