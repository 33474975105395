import { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import URLModal from '../../component/Modals/URLModal';
import { Popover, Transition } from '@headlessui/react';
import { MenuIcon, XIcon, PlusIcon, CheckIcon } from '@heroicons/react/outline';
import Editor from '@monaco-editor/react';
import CodeExamples from '../../component/LoggedInUserComponents/CodeSamples/CodeExamples';

const navigation = [
  // { name: 'Help', href: '/help' },
  { name: 'Log in', href: '/login' },
  //{ name: 'Sign up', href: '/login' },
];

const features = [
  {
    name: 'Instant Cloud Functions',
    description:
      'Write your function code and click deploy to create cloud functions instantly.',
  },
  {
    name: 'No Command Line Tools',
    description:
      "Create cloud functions instantly in the browser, you don't even need to open the terminal.",
  },
  {
    name: 'NodeJS & Python',
    description:
      'We currently support NodeJS and Python functions, we are addding more languages.',
  },
  {
    name: 'Pre-Paid',
    description:
      "Don't worry about unexpected AWS bills, we will take care of it, you just add balance and use it.",
  },
  {
    name: 'AWS Cloud',
    description:
      'Your functions are deployed on to trustable and reliable Amazon AWS Cloud.',
  },
  {
    name: 'Custom Packages',
    description:
      'You can add your own packages and select RAM according to your requirements.',
  },

];
/*
  {
    name: 'Templates',
    description:
      'Coming soon: We are bringing templates to give you ready to use code snippets.',
  },
  {
    name: 'Cron-Jobs',
    description:
      'Coming soon: You can set a repeat timer and create cronjobs instantly.',
  },
  {
    name: 'Tiny Database',
    description:
      'Coming soon: We are working on a super simple database with zero configuration.',
  },
*/
const tiers = [
  {
    name: 'Hobby',
    href: '#',
    priceMonthly: 12,
    description: 'All the basics for starting a new business',
    includedFeatures: [
      'Potenti felis, in cras at at ligula nunc.',
      'Orci neque eget pellentesque.',
    ],
  },
  {
    name: 'Freelancer',
    href: '#',
    priceMonthly: 24,
    description: 'All the basics for starting a new business',
    includedFeatures: [
      'Potenti felis, in cras at at ligula nunc. ',
      'Orci neque eget pellentesque.',
      'Donec mauris sit in eu tincidunt etiam.',
    ],
  },
  {
    name: 'Startup',
    href: '#',
    priceMonthly: 32,
    description: 'All the basics for starting a new business',
    includedFeatures: [
      'Potenti felis, in cras at at ligula nunc. ',
      'Orci neque eget pellentesque.',
      'Donec mauris sit in eu tincidunt etiam.',
      'Faucibus volutpat magna.',
    ],
  },
];

export default function LandingPage() {
  const [sourceCode, setSourceCode] = useState(CodeExamples.nodejs);

  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [callerURL, setCallerURL] = useState('');

  const freeUserDeploy = async (sourceCode) => {
    try {
      setLoading(true);
      const res = await fetch('https://free.tinyfunction.com/deployFunction', {
        method: 'POST',
        body: JSON.stringify({ sourceString: sourceCode }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await res.json();
      setCallerURL(data.sample_url);
      setShowModal(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert('There was an error. Please try again later.');
      console.log(error);
    }
  };

  return (
    <>
      <div className="relative bg-white overflow-hidden">
        <div className="relative pt-6">
          <Popover>
            {({ open }) => (
              <>
                <div className="max-w-7xl mx-auto px-4 sm:px-6">
                  <nav
                    className="relative flex items-center justify-between sm:h-10 md:justify-center"
                    aria-label="Global"
                  >
                    <div className="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
                      <div className="flex items-center justify-between w-full md:w-auto">
                        <Link to="/">
                          <span className="sr-only">Tiny Function</span>
                          <img
                            className="h-8 w-auto sm:h-10"
                            src="/logo-with-text-orange2.png"
                            alt="Tiny Function"
                          />
                        </Link>
                        <div className="-mr-2 flex items-center md:hidden">
                          <Popover.Button className="rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-amber-500">
                            <span className="sr-only">Open main menu</span>
                            <MenuIcon className="h-6 w-6" aria-hidden="true" />
                          </Popover.Button>
                        </div>
                      </div>
                    </div>
                    <div className="hidden md:absolute md:flex md:items-center md:justify-end md:inset-y-0 md:right-0 gap-8">
                      <div className="hidden md:flex md:space-x-8">
                        {navigation.map((item) => (
                          <Link
                            key={item.name}
                            to={item.href}
                            className="font-medium text-gray-500 hover:text-gray-900"
                          >
                            {item.name}
                          </Link>
                        ))}
                      </div>
                      <span className="inline-flex rounded-md shadow">
                        <Link
                          to="/login"
                          className="inline-flex items-center px-4 py-2 border border-transparent text-base font-semibold rounded-md text-white bg-black hover:bg-amber-500 gap-2"
                        >
                          Create
                          <PlusIcon className="h-4 w-4" />
                        </Link>
                      </span>
                    </div>
                  </nav>
                </div>

                <Transition
                  show={open}
                  as={Fragment}
                  enter="duration-150 ease-out"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="duration-100 ease-in"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Popover.Panel
                    focus
                    static
                    className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
                  >
                    <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                      <div className="px-5 pt-4 flex items-center justify-between">
                        <div>
                          <img
                            className="h-8 w-auto"
                            src="/graphics-works/vaisnavi-logo/IMG_3727-Right-facing-2.png"
                            alt=""
                          />
                        </div>
                        <div className="-mr-2">
                          <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-amber-500">
                            <span className="sr-only">Close menu</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </Popover.Button>
                        </div>
                      </div>
                      <div className="px-2 pt-2 pb-3">
                        {navigation.map((item) => (
                          <Link
                            key={item.name}
                            to={item.href}
                            className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                          >
                            {item.name}
                          </Link>
                        ))}
                      </div>
                      <Link
                        to="/login"
                        className="block w-full px-5 py-3 text-center font-medium text-amber-400 bg-gray-50 hover:bg-gray-100"
                      >
                        Create Function
                      </Link>
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>

          <main className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24">
            <div className="text-center">
              <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-4xl md:text-5xl">
                Simplest way to create Cloud Functions
              </h1>
              <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                No installation, no command-line tools.
                <br />
                Write code and deploy functions from the browser.
              </p>
            </div>
          </main>
        </div>
      </div>
      <div className="bg-white pt-16 px-4">
        <Editor
          language="javascript"
          theme="vs-dark"
          height={300}
          className="max-w-5xl mx-auto rounded-lg border-4 border-yellow-500"
          value={sourceCode}
          onChange={(value) => setSourceCode(value)}
          options={{
            fontSize: 20,
            minimap: {
              enabled: false,
            },
          }}
        />
      </div>
      <div className="flex bg-white flex-col gap-2">
        <div className="mt-8 max-w-md mx-auto sm:flex sm:justify-center md:mt-12">
          <div className="rounded-md shadow flex flex-col">
            <button
              className="w-full flex gap-4 items-center justify-center px-8 py-3 border border-transparent text-base font-semibold rounded-md text-white md:py-3 md:text-lg  bg-amber-500 hover:bg-black"
              onClick={() => freeUserDeploy(sourceCode)}
            >
              Deploy
              {loading && (
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              )}
            </button>
            {showModal && (
              <URLModal
                deploymentStatus="SUCCESS"
                sampleUrl={`https://free.tinyfunction.com${callerURL}`}
                onClose={() => setShowModal(false)}
              />
            )}
          </div>
        </div>
        <small className="text-sm text-center text-gray-500">
          Sign-in for full options
        </small>
      </div>
      <div className="bg-white">
        <div className="max-w-6xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-4xl font-extrabold text-gray-900">Features</h2>
            <p className="mt-4 text-lg text-gray-500">
              We are adding lots of new features soon.
            </p>
          </div>
          <dl className="mt-12 space-y-8 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-8 lg:grid-cols-3 lg:gap-x-8">
            {features.map((feature) => (
              <div key={feature.name} className="relative">
                <dt>
                  <CheckIcon
                    className="absolute h-6 w-6 text-green-500"
                    aria-hidden="true"
                  />
                  <p className="ml-9 text-lg leading-6 font-bold text-gray-900">
                    {feature.name}
                  </p>
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
      <div className="max-w-7xl mx-auto text-center py-16 px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          Create Cloud Functions in Minutes
        </h2>
        <div className="my-6 text-lg text-gray-500">
          <p>It's as simple as one-two-three.</p>
          {/* <p>Take a peek at what our uses have to say.</p> */}
        </div>
        <div className="mt-8 flex justify-center">
          <div className="inline-flex rounded-md shadow">
            <Link
              to="/login"
              className="inline-flex items-center justify-center px-5 py-2 border border-transparent text-base font-semibold rounded-md text-white bg-black hover:bg-amber-500 md:text-lg md:px-10"
            >
              Get started
            </Link>
          </div>
        </div>
      </div>
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-20 px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:flex-col sm:align-center">
            <h1 className="text-4xl font-extrabold text-gray-900 sm:text-center">
              Pricing
            </h1>
            <p className="mt-8 lg:max-w-4xl lg:mx-auto text-xl text-gray-500 sm:text-center">
              Our pricing is simple, pay-and-use. Add some money to your wallet
              and based on your function calls we will deduct from your wallet.
              You don't need to worry about unexpected bills and your balance
              will never expire.
            </p>
            <p className="mt-6 lg:max-w-4xl lg:mx-auto text-xl text-gray-500 sm:text-center">
                For $1 you can make 50,000 function calls with 128MB RAM and 1 second execution time.
            </p>

            <div className="w-full flex justify-center p-8 ">
                    <table class="max-w-2xl text-xl text-gray-500 sm:text-center border-1 border-blue-100 rounded-xl shadow-md sm:rounded-lg">
                        <thead>
                            <tr>
                            <th className="border px-8 py-2">Function Calls</th>
                            <th className="border px-8 py-2">RAM</th>
                            <th className="border px-8 py-2">Cost</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="bg-gray-100">
                            <td className="border px-8 py-2">50,000</td>
                            <td className="border px-8 py-2">128 MB</td>
                            <td className="border px-8 py-2">$1</td>
                            </tr>
                            <tr>
                            <td className="border px-8 py-2">50,000</td>
                            <td className="border px-8 py-2">256 MB</td>
                            <td className="border px-8 py-2">$2</td>
                            </tr>
                            <tr className="bg-gray-100">
                            <td className="border px-8 py-2">50,000</td>
                            <td className="border px-8 py-2">512 RAM</td>
                            <td className="border px-8 py-2">$4</td>
                            </tr>
                        </tbody>
                    </table>
            </div>

            <p className="mt-8 lg:max-w-4xl lg:mx-auto text-xl text-gray-500 sm:text-center">
              We are currently giving free credits. Feel free
              to use them and let us know your feedback on &nbsp;
              <a
                href="https://twitter.com/tiny_function"
                target="_blank"
                rel="noopener noreferrer"
                className=" text-gray-500 hover:text-gray-900 font-semibold"
              >
                Twitter
              </a>
              &nbsp; or email us{' '}
              <span className="font-bold"> contact @ tinyfunction.com</span>
            </p>
          </div>
          {/* Todo: we have to move the pricing info to separate page after beta
          <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-6 lg:max-w-5xl lg:mx-auto">
            {tiers.map((tier) => (
              <div
                key={tier.name}
                className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200"
              >
                <div className="p-6">
                  <h2 className="text-lg leading-6 font-medium text-gray-900">
                    {tier.name}
                  </h2>
                  <p className="mt-4 text-sm text-gray-500">
                    {tier.description}
                  </p>
                  <p className="mt-8">
                    <span className="text-4xl font-extrabold text-gray-900">
                      ${tier.priceMonthly}
                    </span>{' '}
                    <span className="text-base font-medium text-gray-500">
                      /mo
                    </span>
                  </p>
                  <a
                    href={tier.href}
                    className="mt-8 block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
                  >
                    Buy {tier.name}
                  </a>
                </div>
                <div className="pt-6 pb-8 px-6">
                  <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">
                    What's included
                  </h3>
                  <ul className="mt-6 space-y-4">
                    {tier.includedFeatures.map((feature) => (
                      <li key={feature} className="flex space-x-3">
                        <CheckIcon
                          className="flex-shrink-0 h-5 w-5 text-green-500"
                          aria-hidden="true"
                        />
                        <span className="text-sm text-gray-500">{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>  */}
        </div>
      </div>
      <footer className="bg-gray-100">
        <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
          <nav
            className="-mx-5 -my-2 flex flex-wrap justify-center"
            aria-label="Footer"
          >
            {navigation.map((item) => (
              <div key={item.name} className="px-5 py-2">
                <a
                  href={item.href}
                  className="text-base text-gray-500 hover:text-gray-900 font-semibold"
                >
                  {item.name}
                </a>
              </div>
            ))}
            <div key="privacy" className="px-5 py-2">
              <a
                href="/privacy.html"
                target="_blank"
                className="text-base text-gray-500 hover:text-gray-900 font-semibold"
              >
                Privacy Policy
              </a>
            </div>
            <div key="contact-email" className="px-5 py-2">
              <a
                href="mailto:contact@tinyfunction.com"
                className="text-base text-gray-500 hover:text-gray-900 font-semibold"
              >
                Email
              </a>
            </div>
            <div key="contact-twitter" className="px-5 py-2">
              <a
                href="https://twitter.com/tiny_function"
                target="_blank"
                rel="noopener noreferrer"
                className="text-base text-gray-500 hover:text-gray-900 font-semibold"
              >
                Twitter
              </a>
            </div>
          </nav>
          {/*<a
            href="https://www.producthunt.com/posts/tinyfunction?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-tinyfunction"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=315500&theme=light"
              alt="TinyFunction - World&#0039;s&#0032;fastest&#0032;way&#0032;to&#0032;create&#0032;cloud&#0032;functions | Product Hunt"
              style={{ width: 250, height: 54, margin: '2rem auto' }}
              width="250"
              height="54"
            />
          </a> */}

          <a
            href="https://www.producthunt.com/posts/tinyfunction?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-tinyfunction"
            target="_blank"
          >
            <img
              src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=315500&theme=light&period=daily"
              alt="TinyFunction - World&#0039;s&#0032;fastest&#0032;way&#0032;to&#0032;create&#0032;cloud&#0032;functions | Product Hunt"
              style={{ width: 250, height: 54, margin: '2rem auto' }}
            />
          </a>

          <p className="mt-4 text-center text-base text-yellow-500 font-semibold">
            &copy; {new Date().getFullYear()} Tiny Function
          </p>
        </div>
      </footer>
    </>
  );
}
