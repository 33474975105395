import React from 'react';

function SignInButton(props) {
  let cursorWait = props.isActionInProgress ? ' cursor-wait ' : '';
  return (
    <button
      className={
        'mt-5 font-bold shadow-sm  uppercase rounded px-4 py-1 w-full bg-amber-500 text-white flex items-center justify-center hover:bg-amber-600 focus:outline-none ' +
        cursorWait
      }
      disabled={props.isActionInProgress}
      onClick={() => {
        props.onClickMethod();
      }}
    >
      {props.name}
    </button>
  );
}

export default SignInButton;
