const rTabs = (str) => str.trim().replace(/^ {4}/gm, '');

const CodeExamples = {
  nodejs: `function add(a, b) {
  const result = Number(a) + Number(b);
  return result;
}`,
  python: rTabs(`
  def add(a,b):
  return a+b
  `),
};

export default CodeExamples;
