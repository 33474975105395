import React, { useEffect, useState } from 'react';
import { COMMON_URL, LANGUAGES, REGEX, STATUS, URLs } from '../../Constants/index';
import { firestore } from '../../config/firebase';
import { observer } from 'mobx-react';
import { appState } from '../../modals/basemodal';
import { getFunctionName, getInputDataForDeploy, getParamsList } from './DeployHelper';
import AcknowledgeModal from '../Modals/AcknowledgeModal';
import ModalWithOkAndCancel from '../Modals/ModalWithOkAndCancel';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import UrlDisplayModal from '../Modals/URLModal';
import { getDeployedURLFromDocID, loadFunctions, loadUserFunctionsAndSetDefault, mouseBusy } from '../Utils/Utils';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Deploy = observer((props) => {
  let urltest = { sample_url: '' };
  const DEV_TESTING = false;

  if (DEV_TESTING === true) {
    urltest = {
      url: COMMON_URL + '/call/asdfasdf',
      sample_url: COMMON_URL + '/call/asdfasdf?a=4&b=4',
    };
  }
  const [functionURL, setFunctionUrl] = useState(urltest);
  const [isSpinner, setIsSpinner] = useState(false);
  const [isDeploymentStatusDisplayModalOpen, setIsDeploymentStatusDisplayModalOpen] = useState(false);
  const [deploymentStatus, setDeploymentStatus] = useState(STATUS.Fail);

  const axios = require('axios');
  const deployString = appState.currentActiveFunction.deployURL ? 'ReDeploy' : 'Deploy';

  async function onDeployButtonClick() {
    setIsSpinner(true);
    mouseBusy(true);
    let reDeploy = appState.currentActiveFunction.deployURL ? true : false;
    const functionName = getFunctionName(appState.currentActiveFunction.sourceString);
    appState.currentActiveFunction.functionName = functionName;
    let docID = await getDocIdOfTheFunction();
    if (reDeploy) {
      await reDeployFunction(docID);
    } else {
      if (docID) {
        //TODO: show the modal that function already exists
        let deployedURL = await getDeployedURLFromDocID(docID);
        if (deployedURL) {
          appState.showAcknowledgmentModal = true;
        } else {
          await deployToAWSAndUpdateShortURLInFirestore(docID);
        }
      } else {
        await deployFunction();
        await loadFunctions();
      }
    }
    setIsSpinner(false);
    mouseBusy(false);
  }

  function hideDuplicateFunctionsDialog() {
    appState.showAcknowledgmentModal = false;
    loadFunctions();
  }

  async function deployFunction(docID) {
    docID = await saveToFirestore();
    await deployToAWSAndUpdateShortURLInFirestore(docID);
    //TODO: Add Validation for valid script
  }

  async function reDeployFunction(docID) {
    await updateFunctionDetailsInFirestore(docID);
    await deployToAWSAndUpdateShortURLInFirestore(docID);
  }

  async function updateFunctionDetailsInFirestore(docID) {
    let paramsList = getParamsList(appState.currentActiveFunction.sourceString);
    await firestore
      .collection('users')
      .doc(appState.userInfo.loggedInUser.uid)
      .collection('projects')
      .doc(appState.selectedProject)
      .collection('functions')
      .doc(docID)
      .update({
        code: appState.currentActiveFunction.sourceString,
        name: appState.currentActiveFunction.functionName,
        paramsList: paramsList,
        dependency: appState.currentActiveFunction.dependency,
        language: appState.currentActiveFunction.language,
        memory: appState.currentActiveFunction.RAM,
      })
      .then(() => {
        console.log('Updated the document with new function details ');
      })
      .catch((error) => {
        console.error('Error updating the document with new function details: ', error);
      });
  }

  async function deployToAWSAndUpdateShortURLInFirestore(docID) {
    let shortURL = await deployFunctionToAWS();
    if (docID && shortURL) {
      await updateURLInFirestore(docID, shortURL);
    } else {
      console.log('Failed to Update URL in firestore: docID or shortURL is null');
    }
  }

  async function updateURLInFirestore(docID, shortURL) {
    await firestore
      .collection('users')
      .doc(appState.userInfo.loggedInUser.uid)
      .collection('projects')
      .doc(appState.selectedProject)
      .collection('functions')
      .doc(docID)
      .update({
        deployURL: shortURL,
      })
      .then(() => {
        console.log('Updated the document with short URL ');
      })
      .catch((error) => {
        console.error('Error updating the document with short URL: ', error);
      });
  }

  async function deployFunctionToAWS() {
    let shortURL = null;
    let inputData = await getInputDataForDeploy();
    let deployURL = URLs.deploy;

    await axios
      .post(deployURL, inputData)
      .then(async (response) => {
        console.log('RESPONSE------->', JSON.stringify(response.data));
        if (response.data.status == STATUS.Success) {
          shortURL = response.data['short-url'].shortURL;
          setDeploymentStatus(STATUS.Success);
          setFunctionUrl(shortURL);
          appState.currentActiveFunction.deployURL = shortURL;
          setIsDeploymentStatusDisplayModalOpen(true);
        } else if (response.data.status == STATUS.Fail) {
          setDeploymentStatus(STATUS.Fail);
          await setFunctionUrl('Failed to deploy the function');
          setIsDeploymentStatusDisplayModalOpen(true);
        }
      })
      .catch((error) => {
        setFunctionUrl({ res: 'Failed to deploy:' });
      });
    return shortURL;
  }

  async function getDocIdOfTheFunction() {
    let docID = null;
    let functionsRef = firestore
      .collection('users')
      .doc(appState.userInfo.loggedInUser.uid)
      .collection('projects')
      .doc(appState.selectedProject)
      .collection('functions');
    let snapshot = await functionsRef.where('name', '==', appState.currentActiveFunction.functionName).get();
    if (snapshot) {
      snapshot.forEach((doc) => {
        console.log('>-->', doc);
        docID = doc.id;
      });
    }
    return docID;
  }

  async function onFunctionDeleteClick() {
    appState.showModalWithOkAndCancel = true;
  }

  async function deleteFunctionFromFirestore() {
    let docID = await getDocIdOfTheFunction();
    if (docID) {
      let firestoreResonse = await firestore
        .collection('users')
        .doc(appState.userInfo.loggedInUser.uid)
        .collection('projects')
        .doc(appState.selectedProject)
        .collection('functions')
        .doc(docID)
        .delete();
      console.log('firestoreResonse-----', firestoreResonse);
    }
  }

  async function onDeleteConfirmation() {
    mouseBusy(true);
    if (appState.currentActiveFunction.deployURL) {
      const response = await fetch(URLs.deleteFunction, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          tfShortUserId: appState.userInfo.shortUID,
          tfProjectId: appState.selectedProject,
          tfFunctionName: appState.currentActiveFunction.functionName,
        }),
      });
      const data = await response.json();
      if (data.status === STATUS.Success) {
        deleteFunctionFromFirestoreAndUpdateUI();
      } else {
        toast.error('There was an error while deleting the function');
      }
    } else {
      deleteFunctionFromFirestoreAndUpdateUI();
    }
    mouseBusy(false);
  }

  async function deleteFunctionFromFirestoreAndUpdateUI() {
    await deleteFunctionFromFirestore();
    loadUserFunctionsAndSetDefault();
    deleteActionResultDisplay();
  }

  function deleteActionResultDisplay() {
    toast.dark('Hey, Function succesfully deleted!');
  }
  async function saveToFirestore() {
    var docId = null;
    var paramsList = getParamsList(appState.currentActiveFunction.sourceString);
    await firestore
      .collection('users')
      .doc(appState.userInfo.loggedInUser.uid)
      .collection('projects')
      .doc(appState.selectedProject)
      .collection('functions')
      .add({
        code: appState.currentActiveFunction.sourceString,
        name: appState.currentActiveFunction.functionName,
        paramsList: paramsList,
        dependency: appState.currentActiveFunction.dependency,
        language: appState.currentActiveFunction.language,
        memory: appState.currentActiveFunction.RAM,
        deployURL: null,
      })
      .then((docRef) => {
        docId = docRef.id;
        console.log('Document written with ID: ', docRef.id);
      })
      .catch((error) => {
        console.error('Error adding document: ', error);
      });
    return docId;
  }
  function textForFunctionAlreadyExists() {
    let currentFunctionName = appState.currentActiveFunction.functionName;
    return 'A function with the name "' + currentFunctionName + '" already exists in the project. Please change the function name';
  }

  const copyToClipboard = (shortURL) => {
    navigator.clipboard.writeText(`${COMMON_URL}/call/${shortURL}`);
  };

  return (
    <div className='pt-3'>
      {appState.currentActiveFunction.deployURL && (
        <div className='flex p-1 px-2 justify-center mt-2 font-semibold'>
          {'Function URL:'}
          <div className='  text-red-600 ml-1'>
            {COMMON_URL + '/call/' + appState.currentActiveFunction.deployURL}
            <button className='justify-end' onClick={() => copyToClipboard(appState.currentActiveFunction.deployURL)}>
              <FontAwesomeIcon icon={faCopy} className='bg-white h-3 w-3 rounded-full align-middle p-2 ml-2 border-black text-red-600 hover:text-' />
            </button>
          </div>
        </div>
      )}
      <div className='flex gap-1 justify-between'>
        <ToastContainer />

        <div className='space-x-2'>
          <button
            className='px-6 py-2 my-1 text-lg font-semibold text-white rounded-lg bg-amber-500 hover:bg-black'
            onClick={() => onDeployButtonClick()}
          >
            {isSpinner ? 'Deploying... ' : deployString}
            {isSpinner && (
              <div className='spinner-border' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
            )}
          </button>
        </div>

        <button className='justify-end' onClick={() => onFunctionDeleteClick()}>
          <FontAwesomeIcon icon={faTrashAlt} className='bg-red-400 hover:bg-red-600 h-4 w-4 rounded-full align-middle p-3 text-white' />
        </button>
      </div>
      {isDeploymentStatusDisplayModalOpen && (
        <UrlDisplayModal
          deploymentStatus={deploymentStatus}
          sampleUrl={COMMON_URL + '/call/' + functionURL}
          onClose={() => setIsDeploymentStatusDisplayModalOpen(false)}
        />
      )}
      {appState.showAcknowledgmentModal && (
        <AcknowledgeModal
          heading={'Function name already exists!'}
          content={textForFunctionAlreadyExists()}
          onOkClick={() => hideDuplicateFunctionsDialog()}
        />
      )}
      {appState.showModalWithOkAndCancel && (
        <ModalWithOkAndCancel
          heading={'Confirm Deletion'}
          content={'Are you sure you want to delete this function?'}
          onOkClick={() => onDeleteConfirmation()}
          button1Name={'Yes, Delete'}
          button2Name={'Cancel'}
        />
      )}
    </div>
  );
});

export default Deploy;
