import React from 'react';
import { observer } from 'mobx-react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import HelpPage from '../help';
import LoginComponent from '../SignUp/LoginComponent';
import { Navbar } from '../NavBar/Navbar';
import Footer from '../Footer';
import LandingPage from '../../pages/landing-page/LandingPage';
// import MainArea from '../NotLoggedInUserComponents/MainArea';

const NotLoggedInUserApp = observer(() => {
  return (
    <BrowserRouter>
      {window.location.pathname !== '/' && <Navbar />}
      <Switch>
        <Route exact path="/help" component={HelpPage} />
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/login" component={LoginComponent} />
      </Switch>
      {window.location.pathname !== '/' && <Footer />}
    </BrowserRouter>
  );
});

export default NotLoggedInUserApp;
