import React from 'react';
import { observer } from 'mobx-react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import HelpPage from '../help';
import LoginComponent from '../SignUp/LoginComponent';
import MainArea from '../LoggedInUserComponents/MainArea';
import Checkout from '../Payment/Payment';
import Success from '../Payment/Success';
import Canceled from '../Payment/Cancelled';

const LoggedInUserApp = observer(() => {
  return (
    <div className='bg-yellow-300'>
      <div className='flex flex-col w-full min-h-screen mx-auto'>
        <BrowserRouter>
          <div className='h-full max-h-full'>
            <Switch>
              <Route exact path='/help' component={HelpPage} />
              <Route exact path='/' component={MainArea} />
              <Route exact path='/login' component={LoginComponent} />
              <Route exact path='/pricing' component={Checkout} />
              <Route exact path='/payment/success' component={Success} />
              <Route exact path='/payment/canceled' component={Canceled} />
            </Switch>
          </div>
        </BrowserRouter>
      </div>
    </div>
  );
});

export default LoggedInUserApp;
