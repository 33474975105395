import { useState, useEffect } from 'react';
import { appState } from '../../modals/basemodal';
import { observer } from 'mobx-react';
import React from 'react';
import useStateWithCallback from 'use-state-with-callback';
import { observe } from 'mobx';
import { loadDefaultFunc, loadFunctions, loadNewFunction, updateTextEditor } from '../Utils/Utils';

const UserFunctions = observer(() => {
  const [background, setBackground] = useState('bg-blue-200');
  /*const [functions, setFunctions] = useStateWithCallback([], () => {
    loadDefaultFunc();
  });*/

  useEffect(() => {
    loadFunctions();
    observe(appState, 'selectedProject', (change) => {
      loadFunctions();
    });
    observe(appState, 'currentFunctionIndex', (change) => {
      loadDefaultFunc();
    });
  }, []);


  return (
    <div className='flex flex-col space-y-1 mt-1'>
      {appState.userFunctionsList.map((func, functionIndex) => {
        const bgColor = functionIndex === appState.currentFunctionIndex ? 'font-medium bg-blue-100' : '';
        const lang = func.language;
        return (
          <div className={"pl-2 flex items-center rounded-full cursor-pointer " + bgColor}
                onClick={() => {
                    updateTextEditor(func, functionIndex);
                }} 
                key={functionIndex}>
            <img className='w-auto h-5' src={"/assets/images/"+ lang +".png"} alt='' />
            <div
                key={functionIndex}
                className={'p-0.5 text-gray-700 border-b-1 pl-1  ' }
            >
                {func.name}
            </div>
          </div>
        );
      })}
    </div>
  );
});

export default UserFunctions;
