// Tiny Function Constants
const DEVELOPMENT_MODE = process.env.NODE_ENV === 'development';

export const LANGUAGES = {
  JAVASCRIPT: 'nodejs',
  PYTHON: 'python',
};

export const LANGUAGES_LIST = [
  { name: 'JavaScript', value: LANGUAGES.JAVASCRIPT },
  { name: 'Python', value: 'python' },
];

export const MEMORY = [
  { name: '128 MB', value: '128' },
  { name: '256 MB', value: '256' },
  { name: '512 MB', value: '512' },
  { name: '1 GB', value: '1024' },
  { name: '2 GB', value: '2048' },
  { name: '3 GB', value: '3072' },
  { name: '4 GB', value: '4096' },
  { name: '5 GB', value: '5120' },
  { name: '6 GB', value: '6144' },
  { name: '7 GB', value: '7168' },
  { name: '8 GB', value: '8192' },
  { name: '9 GB', value: '9216' },
  { name: '10 GB', value: '10240' },
];

export const TINYFUNCTION_NO_REPLY_EMAIL_ID =
  'noreply@tinyfunction-com.firebaseapp.com';
export const DEFAULT_PROJECT = 'default-project';
export const DEFAULT_FUNCTION_NAME = 'add';
export const COMMON_URL = DEVELOPMENT_MODE
  ? 'http://localhost:80'
  : 'https://api.tinyfunction.com';
export const FREE_COMMON_URL = 'https://free.tinyfunction.com';
export const BALANCE_MULTIPLIER = 10000;

export const FreeUserURLs = {
  deploy: FREE_COMMON_URL + '/deployFunction',
};
export const URLs = {
  getUIDFromRedis: COMMON_URL + '/new-account-creation',
  deploy: COMMON_URL + '/deploy',
  test: COMMON_URL + '/testget',
  paymentSession: COMMON_URL + '/create-checkout-session',
  deleteFunction: COMMON_URL + '/deleteFunction',
};

/* 
  javascriptRegexToRemoveComments: /((?<=\/\/).*)|((?<=\/\*).*(?<=\*))/g,
  regexToExtractFuncNameWithParams: /(?<=function ).*(?<=\))/,
  regexToExtractFuncName: /.*(?=\()/,
  regexToExtractFuncParams: /(?<=\().*(?=\))/,
  functionCallString: ' func = ',
  pythonRegexToRemoveComments: /((?<=#).*)|(((?<=\"\"\").*)|(.*(?=\"\"\")))/g,
  pythonRegexToExtractFuncNameAndParam: /(?<=def ).*(?<=\))/,
  pythonRegexToExtractFunctionName: /.*(?=\()/,
  pythonRegexToExtarctParamNames: /(?<=\().*(?=\))/,
  pythonRegexToExtractParams: /((?=:).*(?=,))|((?=:).*(?=$))|((?=\=).*(?=,))|((?=\=).*(?=$))/,
  */

export const HELPURL =
  'https://www.notion.so/TinyFunction-FAQ-Help-fb3f9210fb474d3288e5a93ef5d54d6a';
export const REGEX = {
  javascriptRegexToRemoveComments: /\/\/.*|\/\*[^]*\*\//g, // Tested
  regexToExtractFuncName: /^function\s*([^\s(]+)/, // Tested
  regexToExtractFuncParams: /\(([^)]+)\)/, // Tested
  functionCallString: ' func = ',
  pythonRegexToRemoveComments: /(#.*\n)|(#.*$)/gm, // Tested
  pythonRegexToExtractFunctionName: /def\s+(\w+)\s*\(([\w\s,]*)\):/,
  pythonRegexToExtarctParamNames: /def\s+(\w+)\s*\(([\w\s,]*)\):/,
  pythonRegexToExtractParams:
    /((?=:).*(?=,))|((?=:).*(?=$))|((?=\=).*(?=,))|((?=\=).*(?=$))/,
};

export const USER_TYPES = {
  freeUser: 'Free User',
  paidUser: 'Paid User',
};

export const MAX_ALLOWED_FUNCTIONS_FOR_FREE_USER = 10;
export const STATUS = {
  Success: 'SUCCESS',
  Fail: 'FAIL',
};
