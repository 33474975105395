import { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import React from 'react';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

const CustomDropDown = observer((props) => {

const options = props.options

function handleChange(e) {
  props.onSelect(e);
}

const defaultOption = props.defaultOption;
return(<div>
<Dropdown options={options} onChange={(e) => handleChange(e)} className='dropdown' value={defaultOption} placeholder="Select an option" />

</div>

)
})

export default CustomDropDown;