import { useState, useEffect } from 'react';
import { firestore } from '../../config/firebase';
import { appState } from '../../modals/basemodal';
import { observer } from 'mobx-react';
import React from 'react';
import FunctionsDropDownMenu from './FunctionsDropDownMenu';
/*
    Temperorily hiding TinyDatabase
import DatabaseDropDownMenu from './DatabaseDropDownMenu'; 
*/
import CustomDropDown from '../Tools/CustomDropDown';

const SidePanel = observer(() => {
  const [projects, setProjects] = useState([]);

  useEffect(async () => {
    const snapshot = await firestore.collection('users').doc(appState.userInfo.loggedInUser.uid).collection('projects').get();
    snapshot.docs.map((doc) => {
      setProjects((projects) => [...projects, doc.id]);
    });
  }, []);

  function onProjectChange(newSelectedProject) {
    appState.selectedProject = newSelectedProject.value;
    appState.selectedFunctionIndex = 0;
  }

  return (
    <div className='flex flex-col w-full h-full mt-14 overflow-hidden bg-white shadow-lg px-3'>
      <div>
        <CustomDropDown options={projects} defaultOption={appState.selectedProject} onSelect={onProjectChange} />
        <FunctionsDropDownMenu />
      </div>

      { /* 
        Temperorily hiding TinyDatabase component
      <DatabaseDropDownMenu />*/}
    </div>
  );
});

export default SidePanel;
