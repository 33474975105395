import { FaTwitter } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className='bg-gray-800 text-white px-4 py-8 md:p-24'>
      <div className='flex justify-between items-end w-full mx-auto'>
        <div>
          <img className='mb-4' src='/logo.png' width='250' />
          <p>&copy; {new Date().getFullYear()} Tiny Function</p>
        </div>
        <div className='flex items-center gap-x-5'>
          <a
            href='https://www.notion.so/TinyFunction-FAQ-Help-fb3f9210fb474d3288e5a93ef5d54d6a'
            target='_blank'
            rel='noreferrer'
            className='hover:underline'
          >
            FAQ
          </a>
          <a href='https://twitter.com/tiny_function' target='_blank' rel='noreferrer'>
            <FaTwitter className='h-4 w-4 hover:text-gray-400 transition' />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
