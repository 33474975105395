import React from 'react';
import { FaQuestion } from 'react-icons/fa';
import { HELPURL } from '../../Constants';

function Help() {
  return (
    <a href={HELPURL} target='_blank' rel='noreferrer' className=' my-auto text-lg text-gray-700 hover:underline'>
      <FaQuestion className='bg-amber-300 helpIcon mx-5' />
    </a>
  );
}

export default Help;
