import React from 'react';
import { observer } from 'mobx-react';
import { appState } from '../../modals/basemodal';

const ModalWithOkAndCancel = observer((props) => {
  function onOkClick() {
    if (props.onOkClick) {
      props.onOkClick();
    }
    appState.showModalWithOkAndCancel = false;
  }

  function onCancelClick() {
    if (props.onCancelClick) {
      props.onCancelClick();
    }
    appState.showModalWithOkAndCancel = false;
  }

  return (
    <div className='fixed inset-0 z-10 overflow-y-auto'>
      <div className='flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0'>
        <div className='fixed inset-0 transition-opacity' aria-hidden='true'>
          <div className='absolute inset-0 bg-gray-500 opacity-75'></div>
        </div>
        <span className='hidden sm:inline-block sm:align-middle sm:h-screen' aria-hidden='true'>
          &#8203;
        </span>

        <div
          className='inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full'
          role='dialog'
          aria-modal='true'
          aria-labelledby='modal-headline'
        >
          <div className='px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4'>
            <div className=''>
              <div className='flex justify-center'>
                <h3 className='pl-3 text-lg font-semibold leading-6 text-gray-900'>{props.heading}</h3>
              </div>

              <div className='pl-6 mt-3 text-center'>
                <p className='text-sm text-gray-500'>{props.content}</p>
              </div>
            </div>
          </div>
          <div className='flex justify-center space-x-2 px-4 py-3 bg-gray-50 '>
            <button
              className='inline-flex justify-center w-auto px-4 py-2 mb-3 text-base font-medium text-white bg-red-500 rounded-md shadow-xl hover:bg-red-600 focus:outline-none'
              onClick={() => {
                onOkClick();
              }}
            >
              {props.button1Name}
            </button>
            <button
              className='inline-flex justify-center w-auto px-4 py-2 mb-3 text-base font-medium text-white bg-black rounded-md shadow-xl hover:bg-indigo-900 focus:outline-none'
              onClick={() => {
                onCancelClick();
              }}
            >
              {props.button2Name}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ModalWithOkAndCancel;
