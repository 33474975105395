import React from 'react';
import Modal from 'react-modal';
import copy from 'copy-to-clipboard';
import { FaCopy } from 'react-icons/fa';
import { COMMON_URL, STATUS } from '../../Constants/index';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    height: 'auto',
    width: 'auto',
    borderRadius: '16px',
    boxShadow: '10px 10px 20px rgba(0, 0, 0, 0.1)',
  },
};

function UrlDisplayModal(props) {
  const urlParams = props.sampleUrl.split('?');

  function copySampleUrl() {
    copy(props.sampleUrl);
  }

  function displaySuccessData() {
    return (
      <>
        <h5 className='mb-2 text-xl font-bold text-green-600'>
          <span>Congrats! Your function is successfully deployed.</span>
        </h5>
        <hr></hr>
        <div className='my-2'>
          <div>A sample GET url with your function parameters:</div>
        </div>
        <div className='flex items-center bg-gray-200 rounded'>
          <h5 className='p-2 font-bold text-blue-600 break-words max-w-100'>
            {urlParams[0]}
            <span className='font-bold text-pink-500'>?{urlParams[1]}</span>
          </h5>
        </div>
        <div className='w-full'>
          <button
            className='flex justify-center p-2 px-4 mx-auto mt-2 text-sm text-white bg-black  hover:bg-amber-500 rounded-md focus:outline-none focus:ring'
            onClick={() => copySampleUrl()}
          >
            Copy URL
          </button>
        </div>

        <br />
        <div className='text-sm italic font-bold text-gray-600'>Note - This URL can be called with both GET and POST methods.</div>
      </>
    );
  }

  function displayFailureData() {
    return (
      <>
        <h5 className='mb-2 text-xl font-bold text-red-500'>
          <span>Alas! Failed to deploy your function.</span>
        </h5>
        <hr></hr>
        <div>
          <div className='font-bold my-2'>Here are some things that might help you to successfully deploy:</div>
          <div>1. Make sure the code is syntatically correct.</div>
          <div>2. Check if the Package.json/requirements.txt has the required dependencies.</div>
          <div>3. Checkout our sample code which appears on the click of create button for reference.</div>
        </div>
      </>
    );
  }

  return (
    <Modal isOpen={true} onRequestClose={props.onClose} style={customStyles} contentLabel='Example Modal' ariaHideApp={false}>
      <div className='relative p-6'>
        <button className='absolute px-4 py-2 bg-gray-300 rounded-lg -top-5 -right-5' type='button' aria-label='Close' onClick={props.onClose}>
          <span className='text-xl font-bold' aria-hidden='true'>
            &times;
          </span>
        </button>
        {props.deploymentStatus === STATUS.Success? displaySuccessData() : displayFailureData()}
      </div>
    </Modal>
  );
}

export default UrlDisplayModal;
