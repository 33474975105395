import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import UserFunctions from './UserFunctions';

const FunctionsDropDownMenu = observer((props) => {
  const [expandFunctions, setExpandFunctions] = useState(true);

  async function setUserFunctions() {
    setExpandFunctions(!expandFunctions);
  }

  return (
    <>
      <div className={'flex border-b-2 border-gray-100 text-gray-800 font-semibold row mt-2 p-1 w-100 '} onClick={() => setUserFunctions()}>
        <img className='w-auto h-8 mr-2' src='/assets/images/nest3.png' alt='' />
        <span className='my-auto font-semibold text-lg'>Functions</span>
      </div>
      {expandFunctions && <UserFunctions />}
    </>
  );
});

export default FunctionsDropDownMenu;
