import React from 'react'
import { Link } from 'react-router-dom'

export default function Logo() {
    return (
        <div >
            <Link to="/" className="flex">
                {/*<link href="http://fonts.cdnfonts.com/css/delius?text=tiny" rel="stylesheet"/>
                <img className="w-auto h-10 pl-3 pr-1" src="logo-only.png" alt="Tiny Function" />
    <span className="font-bold text-3xl text-amber-500" style={{fontFamily: 'Delius, sans-serif'}} >TinyFunction</span>*/}
                <img className="w-auto h-10" src="logo-with-text-orange2.png" alt="Tiny Function" /> 
            </Link>
        </div>
    )
}
