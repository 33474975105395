import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { appState } from '../../modals/basemodal';
import CodeEditor from './CodeEditor';
import Features from '../Features';
import SidePanel from './SidePanel';
import NewsletterSubscription from '../NewsletterSubscription';
import LoggedInUserNavBar from '../NavBar/LoggedInUserNavbar';
import CodeEditorTab from './CodeEditorTab';

const Description = () => {
  return (
    <div className='my-1 text-center'>
      <h1 className='mb-4 text-3xl font-bold text-gray-700 lg:text-4xl'>Write . Deploy . Call</h1>
      <p className='px-4 text-gray-700 lg:text-lg'>Fastest way to create cloud functions. Write your function, deploy and call them from anywhere.</p>
    </div>
  );
};

const MainArea = observer(() => {
  return (
    <>
      <LoggedInUserNavBar />

      <div className='flex w-full h-full bg-gray-100'>
        <div className='w-0 h-full lg:min-w-2/12 lg:w-2/12'>
          {appState.userInfo.loggedInUser && (
            <div className='h-screen overflow-hidden bg-white '>
              <SidePanel />
            </div>
          )}
        </div>
        <div className='w-full h-full mt-0 leading-relaxed lg:mt-14 lg:w-10/12 lg:min-w-10/12 '>
          <CodeEditorTab />
        </div>
      </div>
    </>
  );
});

export default MainArea;
