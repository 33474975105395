const rTabs = (str) => str.trim().replace(/^ {4}/gm, '');

const DependencyExamples = {
  nodejs: rTabs(`
  {
    "name":"sample",
    "version":"1.0.0",
    "description":"Test file",
    "main":"index.js",
    "scripts":{
       "start":"node index.js"
    },
    "author":"TinyFunction",
    "license":"ISC",
    "dependencies":{
       "uuid":"^8.3.2"
    }
  }
  `),
  python: rTabs(``),
};

export default DependencyExamples;
