import firebase from 'firebase';

const firebaseConfig = {
  apiKey: 'AIzaSyC4Go4Gaf-YTVzj4rsX-o5-nIDD6vpSOpg',
  authDomain: 'tinyfunction-com.firebaseapp.com',
  projectId: 'tinyfunction-com',
  storageBucket: 'tinyfunction-com.appspot.com',
  messagingSenderId: '178667070099',
  appId: '1:178667070099:web:ea9d7f35929b75f979e366',
};

const fireAuth = firebase.initializeApp(firebaseConfig);

export const firestore = firebase.firestore();

export default fireAuth;
