import { observable } from 'mobx';
import { DEFAULT_FUNCTION_NAME, DEFAULT_PROJECT, LANGUAGES, MEMORY } from '../Constants';
import CodeExamples from '../component/LoggedInUserComponents/CodeSamples/CodeExamples';
import DependencyExamples from '../component/LoggedInUserComponents/CodeSamples/DependencyExamples';

const DEFAULT_RAM = MEMORY[0].value;

export var appState = observable({
  isSignUpModalVisible: false,
  userInfo: {
    loggedInUser: false,
    paidUser: false,
    shortUID: null,
    email: null,
  },
  showAcknowledgmentModal: false,
  showModalWithOkAndCancel: false,
  showCancelSubscriptionConfirmation: false,
  codeString: CodeExamples[LANGUAGES.JAVASCRIPT], //To be deprecated
  language: LANGUAGES.JAVASCRIPT,
  databaseData: null,
  databaseType: null,
  selectedProject: DEFAULT_PROJECT,
  currentFunctionIndex: 0,
  isCreateNewFunctionClicked: false,
  currentActiveFunction: {
    language: LANGUAGES.JAVASCRIPT,
    sourceString: CodeExamples[LANGUAGES.JAVASCRIPT],
    functionName: DEFAULT_FUNCTION_NAME,
    dependency: DependencyExamples[LANGUAGES.JAVASCRIPT],
    RAM: DEFAULT_RAM,
    deployURL: null,
  },
  userFunctionsList: [],
});
