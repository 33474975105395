import React from 'react';
import { observer } from 'mobx-react';
import './App.css';
import './index.css';

import { appState } from './modals/basemodal';
import LoggedInUserApp from './component/AppRedirection/LoggedInUserApp';
import NotLoggedInUserApp from './component/AppRedirection/NotLoggedInUserApp';
import fireAuth from './config/firebase';
import { injectStyle } from 'react-toastify/dist/inject-style';

const App = observer(() => {
  if (typeof window !== 'undefined') {
    injectStyle();
  }

  fireAuth.auth().onAuthStateChanged(function (user) {
    if (user) {
      if (!user.emailVerified) {
        return;
      }
      let userRef = fireAuth.firestore().collection('users').doc(user.uid);
      userRef.onSnapshot(function (doc) {
        if (doc && doc.data()) {
          appState.userInfo.paidUser = doc.data().user_type;
          appState.userInfo.shortUID = doc.data().shortUID;
          appState.userInfo.email = doc.data().email;
          console.log('SHORT ID-->', doc.data().shortUID);
        }
      });
      appState.userInfo.loggedInUser = user;
      /*userRef.get().then(function (doc) {
        if (!doc.exists) {
          return userRef.set({
            email: user.email,
            paid_user: false,
          });
        } else {
          appState.userInfo.paidUser = doc.data().user_type;
        }
      });*/
    } else {
      appState.isSignUpModalVisible = false;
      appState.userInfo.loggedInUser = false;
    }
  });

  return <div className='bg-gray-100 h-full'>{appState.userInfo.loggedInUser ? <LoggedInUserApp /> : <NotLoggedInUserApp />}</div>;
});

export default App;
