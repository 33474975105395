import { observe } from 'mobx';
import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { appState } from '../../modals/basemodal';
import firebase from 'firebase';
import fireAuth from '../../config/firebase';
import { Redirect, useHistory } from 'react-router-dom';
import SignInButton from './SignInButton';
import { TINYFUNCTION_NO_REPLY_EMAIL_ID } from '../../Constants/index';

const Login = observer((props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [notification, setNotification] = useState(null);
  const [error, setError] = useState(null);
  const [isLoginTab, setIsLoginTab] = useState(1);
  const [isForgotPassword, setIsForgotPassword] = useState(null);
  const [isActionInProgress, setActionInProgress] = useState(false);
  const history = useHistory();

  function onEmailChange(e) {
    setEmail(e.target.value);
    setNotification(null);
  }
  function onPasswordChange(e) {
    setPassword(e.target.value);
    setNotification(null);
  }

  function handleSignInError(errorMsg) {
    if (errorMsg === 'Error: The password is invalid or the user does not have a password.') setNotification('The password is invalid');
    else if (errorMsg === 'Error: There is no user record corresponding to this identifier. The user may have been deleted.')
      setNotification('There is no user record corresponding to this email');
    else if (errorMsg === 'Error: A network error. Please try again') setNotification('Network error. Please try again in sometime');
    else if (errorMsg === 'Error: The email address is badly formatted.') setNotification('The email address is invalid');
    else setNotification(errorMsg);
  }

  function signInWithEmail(e) {
    setActionInProgress(true);
    fireAuth
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        fireAuth.auth().onAuthStateChanged(function (user) {
          if (user) {
            if (!user.emailVerified) {
              handleSignInError(
                'Your email id is not verified. Please click on the link in the mail sent from ' + TINYFUNCTION_NO_REPLY_EMAIL_ID + ' to verify.'
              );
              return;
            } else {
              console.log('TRYING TO REDIRECT');
              history.push('/');
              window.location.href = '/';
              <Redirect to='/' />;
              appState.userInfo.loggedInUser = user;
              appState.isSignUpModalVisible = false;
            }
          }
        });
        setNotification(null);
        setActionInProgress(false);
      })
      .catch((err) => {
        console.log('ERROR SIGNING IN: ', err);
        handleSignInError(err.toString());
        setActionInProgress(false);
      });
  }

  function forgotPassword(e) {
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(function () {
        setNotification('Email sent. Please check your mailbox');
      })
      .catch(function (error) {
        console.log('FORGOT PASSWORD ERROR: ', error);
        handleSignInError(error.toString());
        // An error happened.
      });
  }

  var paddTop = notification ? ' mt-1' : ' mt-6';
  return (
    <div className='flex flex-col items-center justify-between w-full mb-4 h-5/6'>
      <div className='w-full'>
        <div className={'w-full flex flex-col justify-between items-start ' + paddTop}>
          <div className=' text-sm font-bold text-left text-gray-500'>Email</div>
          <input
            placeholder='abc@example.com'
            className=' h-8 pl-2 border w-64 border-gray-500 rounded focus:outline-none focus:border-indigo-600'
            onChange={(e) => onEmailChange(e)}
            value={email}
          />
          {!isForgotPassword ? (
            <div className='flex flex-col items-start w-full'>
              <div className=' mt-3 text-sm font-bold text-left text-gray-500'>Password</div>
              <input
                type='password'
                placeholder='Password'
                className=' h-8 pl-2 border w-64 border-gray-500 rounded focus:outline-none focus:border-indigo-600'
                onChange={(e) => onPasswordChange(e)}
                value={password}
              />
            </div>
          ) : null}
          {notification && <div className='px-6 py-2 w-64 mt-2 text-sm font-bold text-center text-red-700 bg-red-200 rounded'>{notification}</div>}
          {!isForgotPassword ? (
            <SignInButton onClickMethod={signInWithEmail} name={'Sign in'} isActionInProgress={isActionInProgress}></SignInButton>
          ) : (
            <SignInButton onClickMethod={() => forgotPassword()} name={'Reset Password'}></SignInButton>
          )}
          {!isForgotPassword ? (
            <button
              className='mt-2 mx-auto text-sm font-bold text-amber-500 hover:underline focus:outline-none'
              onClick={() => setIsForgotPassword(true)}
            >
              Forgot Password?
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
});
export default Login;
