import React from 'react';
import { observer } from 'mobx-react';
import { URLs } from '../../Constants';
import axios from 'axios';
import { appState } from '../../modals/basemodal';

const Checkout = observer(() => {
  async function onPayClick() {
    let response = await axios.post(URLs.paymentSession, {
      amountToRecharge: 1,
      shortID: appState.userInfo.shortUID,
      email: appState.userInfo.email,
    });
    const body = await response.data;
    if (body.url) {
      window.location.href = body.url;
    }
  }

  return (
    <div className=' mt-28'>
      <button className='p-3 px-4 bg-gray-100 text-black align-middle justify-center' onClick={() => onPayClick()}>
        Pay $1
      </button>
    </div>
  );
});

export default Checkout;
