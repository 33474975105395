import { observer } from 'mobx-react';
import React from 'react';
import { Navbar } from '../NavBar/Navbar';
import LoginPopup from './LoginPopup';

const LoginComponent = observer(() => {
  return (
    <>
    <Navbar/>
      <div className="flex justify-center items-center w-full py-32 bg-gray-100">
        <div className="flex bg-white border shadow-md rounded-2xl">
          <div className="px-8 py-6 bg-gray-50 rounded-2xl">
            <LoginPopup />
          </div>
        </div>
      </div>
    </>
  );
});

export default LoginComponent;
