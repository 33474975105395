import { observer } from 'mobx-react';
import React, { useState } from 'react';
import CodeEditor from './CodeEditor';
import { appState } from '../../modals/basemodal';
import { LANGUAGES, LANGUAGES_LIST, MEMORY } from '../../Constants';
import CodeExamples from './CodeSamples/CodeExamples';
import DependencyExamples from './CodeSamples/DependencyExamples';

const CodeEditorTab = observer(() => {
  const [codeTab, setCodeTab] = useState(1);

  let textStyleForCodeTab = codeTab ? ' text-white bg-gray-800 ' : ' bg-gray-500 text-white';
  let textStyleForDependenciesTab = codeTab ? ' bg-gray-500 text-white' : ' text-white bg-gray-800 ';

  function updateCodeOnChange(newCode) {
    appState.currentActiveFunction.sourceString = newCode;
  }

  function updateDependencyOnChange(newDependency) {
    appState.currentActiveFunction.dependency = newDependency;
  }

  function onlanguagechange(chosenLanguage) {
    appState.currentActiveFunction.language = chosenLanguage;
    appState.currentActiveFunction.sourceString = CodeExamples[chosenLanguage];
    appState.currentActiveFunction.dependency = DependencyExamples[chosenLanguage];
  }

  return (
    <div>
      <div className='w-5/6 mx-auto h-full bg-gray-100 '>
        <div className='flex justify-between mt-6'>
          <div className='float-right'>
            <select
              className='px-1 py-2 rounded-md bg-gray-300 font-semibold'
              value={appState.currentActiveFunction.language}
              disabled={!appState.isCreateNewFunctionClicked}
              onChange={(e) => {
                onlanguagechange(e.target.value);
              }}
            >
              {LANGUAGES_LIST.map((language) => (
                <option key={language.value} value={language.value}>
                  {language.name}
                </option>
              ))}
            </select>
            <select
              className='ml-3 px-1 py-2 rounded-md bg-gray-300 font-semibold'
              value={appState.currentActiveFunction.RAM}
              onChange={(e) => {
                appState.currentActiveFunction.RAM = e.target.value;
              }}
            >
              {MEMORY.map((memory) => (
                <option key={memory.value} value={memory.value}>
                  {memory.name}
                </option>
              ))}
            </select>
          </div>

          <div className='underline hover:cursor-pointer mt-auto font-semibold hidden'>Sample Code Snippets</div>
        </div>
        <nav className='flex w-full mt-3 justify-between'>
          <div className='flex'>
            <button
              className={'rounded-tl font-bold py-1 px-4 text-sm block border-black' + textStyleForCodeTab}
              onClick={() => {
                setCodeTab(1);
              }}
            >
              code
            </button>
            <button
              className={' rounded-tr text-sm font-bold py-1 px-4 block focus:outline-none border-black' + textStyleForDependenciesTab}
              onClick={() => {
                setCodeTab(0);
              }}
            >
              {appState.currentActiveFunction.language === LANGUAGES.JAVASCRIPT ? 'Package.json' : 'requirements.txt'}
            </button>
          </div>
        </nav>
        {codeTab ? (
          <CodeEditor value={appState.currentActiveFunction.sourceString} onChange={(change) => updateCodeOnChange(change)} />
        ) : (
          <CodeEditor value={appState.currentActiveFunction.dependency} onChange={(change) => updateDependencyOnChange(change)} />
        )}
      </div>
    </div>
  );
});

export default CodeEditorTab;
