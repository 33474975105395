import { appState } from '../../modals/basemodal';
import { LANGUAGES, REGEX } from '../../Constants';

export async function getInputDataForDeploy() {
  let reDeploy = appState.currentActiveFunction.deployURL ? true : false;
  let inputData = {
    function_source_code: appState.currentActiveFunction.sourceString,
    function_name: await getFunctionName(
      appState.currentActiveFunction.sourceString
    ),
    function_params: await getParamsList(
      appState.currentActiveFunction.sourceString
    ),
    reDeploy: reDeploy,
    ram: appState.currentActiveFunction.RAM,
    language: appState.currentActiveFunction.language,
    userId: appState.userInfo.shortUID,
    projectId: appState.selectedProject,
    timeout: '120',
    shortURL: appState.currentActiveFunction.deployURL,
  };
  let inputDataWithDependency = getDependency(inputData);
  return inputDataWithDependency;
}

function getDependency(inputData) {
  if (appState.currentActiveFunction.language === LANGUAGES.JAVASCRIPT) {
    inputData.function_package_json = JSON.parse(
      appState.currentActiveFunction.dependency
    );
  } else if (appState.currentActiveFunction.language === LANGUAGES.PYTHON) {
    inputData.function_requirements_txt = escape(
      appState.currentActiveFunction.dependency
    );
  }
  return inputData;
}

export function getFunctionName(sourceString) {
  let funcName = '';
  if (appState.currentActiveFunction.language === LANGUAGES.JAVASCRIPT) {
    funcName = getJavascriptFuncName(sourceString);
  } else if (appState.currentActiveFunction.language === LANGUAGES.PYTHON) {
    funcName = getPythonFuncName(sourceString);
  }
  return funcName;
}

export function getPythonFuncName(sourceString) {
  let sourceFunction = sourceString.replace(
    REGEX.pythonRegexToRemoveComments,
    ''
  );
  // let funcNameAndParam = sourceFunction.match(
  //   REGEX.pythonRegexToExtractFuncNameAndParam
  // )[0];
  let funcName = sourceFunction.match(
    REGEX.pythonRegexToExtractFunctionName
  )[1];
  return funcName;
}

export function getJavascriptFuncName(sourceString) {
  //Add error handling
  let sourceStringAfterRemovingComments = sourceString.replace(
    REGEX.javascriptRegexToRemoveComments,
    ''
  );
  // let sourceFunction = sourceStringAfterRemovingComments.match(
  //   REGEX.regexToExtractFuncNameWithParams
  // )[0];
  let funcName = sourceStringAfterRemovingComments.match(
    REGEX.regexToExtractFuncName
  )[1];
  return funcName;
}

export function getParamsList(sourceString) {
  let paramsList = [];
  if (appState.currentActiveFunction.language === LANGUAGES.JAVASCRIPT) {
    paramsList = getJavascriptParams(sourceString);
  } else if (appState.currentActiveFunction.language === LANGUAGES.PYTHON) {
    paramsList = getPythonParams(sourceString);
  }
  return paramsList;
}

function getJavascriptParams(sourceString) {
  //Gets the parameters list from the given source string
  let paramsList = [];
  if (sourceString) {
    sourceString = sourceString.replace(/{/g, '{\n');
    let sourceStringAfterRemovingComments = sourceString.replace(
      REGEX.javascriptRegexToRemoveComments,
      ''
    );
    // let sourceFunction = sourceStringAfterRemovingComments.match(
    //   REGEX.regexToExtractFuncNameWithParams
    // )[0];
    let funcParams = sourceStringAfterRemovingComments.match(
      REGEX.regexToExtractFuncParams
    )[1];
    let funcParamsAfterRemovingSpace = funcParams.replace(/ /g, '');
    if (funcParamsAfterRemovingSpace !== '') {
      paramsList = funcParamsAfterRemovingSpace.split(',');
    }
  }
  return paramsList;
}

function getPythonParams(sourceString) {
  let sourceFunction = sourceString.replace(
    REGEX.pythonRegexToRemoveComments,
    ''
  );
  // let funcNameAndParam = sourceFunction.match(
  //   REGEX.pythonRegexToExtractFuncNameAndParam
  // )[0];
  let paramsList = sourceFunction
    .match(REGEX.pythonRegexToExtarctParamNames)[2]
    .split(',');
  // let paramsString = paramsList[2].replace(
  //   REGEX.pythonRegexToExtractParams,
  //   ''
  // );
  // let params = paramsString.split(',');
  let params = paramsList.map((param) => param.trim());
  return params;
}
