import { observer } from 'mobx-react';
import React from 'react';
import Editor from '@monaco-editor/react';
import { appState } from '../../modals/basemodal';
import Deploy from './Deploy';

const CodeEditor = observer((props) => {
  const theme = 'vs-dark';

  return (
    <div className=' h-auto '>
      <div className='flex items-end w-full '></div>
      <Editor
        className='editor'
        theme={theme}
        language={appState.currentActiveFunction.language === 'nodejs' ? 'javascript' : appState.currentActiveFunction.language}
        value={props.value}
        onChange={(newValue, e) => {
          props.onChange(newValue);
        }}
        options={{
          minimap: {
            enabled: false,
          },
          fontSize: 18,
        }}
      />
      <Deploy />
    </div>
  );
});

export default CodeEditor;
