import axios from 'axios';
import React from 'react';
import { observer } from 'mobx-react';
import firebase from 'firebase';
import fireAuth, { firestore } from '../../config/firebase';
import SignInButton from './SignInButton';
import { appState } from '../../modals/basemodal';
import { BALANCE_MULTIPLIER, URLs, USER_TYPES } from '../../Constants/index';

const SignUp = observer(
  class LoginPopup extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        email: '',
        password: '',
        showOnlySignUpInput: false,
        notification: null,
        error: null,
        isLoginTab: 1,
      };
    }

    onEmailChange = (e) => {
      this.setState({ email: e.target.value });
      this.setState({ notification: null });
    };
    onPasswordChange = (e) => {
      this.setState({ password: e.target.value });
      this.setState({ notification: null });
    };

    handleSignUpError(errorMsg) {
      if (errorMsg === 'Error: The password is invalid or the user does not have a password.')
        this.setState({ notification: 'The password is invalid' });
      else if (errorMsg === 'Error: The email address is already in use by another account.')
        this.setState({
          notification: 'The email address is already in use, you can login',
        });
      else if (errorMsg === 'Error: The email address is badly formatted.') this.setState({ notification: 'The email address is invalid' });
      else this.setState({ notification: errorMsg });
    }

    signUpWithEmail = (e) => {
      const { email, password } = this.state;
      fireAuth
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(async (user) => {
          fireAuth
            .auth()
            .currentUser.sendEmailVerification()
            .then(() => {
              this.setState({
                notification: 'Thank you. You will receive a verification email from noreply@tiny-cloud.com.',              });
            })
            .catch((err) => {
              console.log('ERROR SENDING EMAIL: ', err);
            });
          appState.showAcknowledgmentModal = true;
          let createUIDInRedisURL = URLs.getUIDFromRedis;
          //Calling Redis to update UUID and get the UID
          //TODO: Error handling
          let response = await axios.post(createUIDInRedisURL, {
            UUID: user.user.uid,
          });
          if (response.data) {
            console.log('CREATE USER ENTRY IN DB');
            await this.createUserEntryInDB(response.data, user);
          } else {
            console.log('Subscribe unsuccessful', response);
          }
        })
        .catch((err) => {
          console.log('Error: ', err);
          this.handleSignUpError(err.toString());
        });
      fireAuth.auth().signOut();
    };

    createUserEntryInDB = async (responseData, userData) => {
      let shortUID = responseData.shortUID;
      let email = userData.user.email;
      let uid = userData.user.uid;
      await this.createUserEntryInFirestore(shortUID, email, uid);
    };

    createUserEntryInFirestore = (shortUID, email, uid) => {
      firestore.collection('users').doc(uid).set({
        email: email,
        user_type: USER_TYPES.freeUser,
        shortUID: shortUID,
      });
    };

    render() {
      var paddTop = this.state.notification ? ' mt-1' : ' mt-6';
      return (
        <div className='flex flex-col items-center justify-between w-full mb-4 h-5/6'>
          <div className='w-full'>
            <div className={'w-full flex flex-col justify-between items-start ' + paddTop}>
              <div className='text-sm font-bold text-left text-gray-500'>Email</div>
              <input
                placeholder='abc@example.com'
                className='h-8 pl-2 border w-64 border-gray-500 rounded focus:outline-none focus:border-indigo-600'
                onChange={(e) => this.onEmailChange(e)}
                value={this.state.email}
              />
              <div className='mt-3 text-sm font-bold text-left text-gray-500'>Password</div>
              <input
                type='password'
                placeholder='Password'
                className='h-8 pl-2 border w-64 border-gray-500 rounded focus:outline-none focus:border-indigo-600'
                onChange={(e) => this.onPasswordChange(e)}
                value={this.state.password}
              />
              {this.state.notification && (
                <div className='px-6 py-2 w-64 mt-2 text-sm font-bold text-center text-red-700 bg-red-200 rounded'>{this.state.notification}</div>
              )}
              <SignInButton onClickMethod={this.signUpWithEmail} name={'SIGN UP'}></SignInButton>
            </div>
          </div>
        </div>
      );
    }
  }
);

export default SignUp;
