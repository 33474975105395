import { DEFAULT_FUNCTION_NAME, LANGUAGES } from '../../Constants';
import { appState } from '../../modals/basemodal';
import CodeExamples from '../LoggedInUserComponents/CodeSamples/CodeExamples';
import DependencyExamples from '../LoggedInUserComponents/CodeSamples/DependencyExamples';
import { firestore } from '../../config/firebase';

export function loadNewFunction() {
  appState.currentActiveFunction.sourceString = CodeExamples[LANGUAGES.JAVASCRIPT];
  appState.currentActiveFunction.dependency = DependencyExamples[LANGUAGES.JAVASCRIPT];
  appState.currentActiveFunction.language = LANGUAGES.JAVASCRIPT;
  appState.currentActiveFunction.deployURL = null;
  appState.isCreateNewFunctionClicked = true;
  appState.currentActiveFunction.functionName = DEFAULT_FUNCTION_NAME;
}

export function loadDefaultFunc() {
  //To load the default first function
  if (appState.userFunctionsList.length > 0) {
    let func = appState.userFunctionsList[appState.currentFunctionIndex];
    if (func) {
      updateTextEditor(func, appState.currentFunctionIndex);
    } else {
      console.log('UserFunctions: function is null');
    }
  } else {
    loadNewFunction();
    console.log('UserFunctions: Functions array is empty');
  }
}

export async function loadFunctions() {
  appState.userFunctionsList = [];
  let userFunctionsList = [];
  await firestore
    .collection('users')
    .doc(appState.userInfo.loggedInUser.uid)
    .collection('projects')
    .doc(appState.selectedProject)
    .collection('functions')
    .get()
    .then((snapshot) => {
      snapshot.forEach((doc, i) => {
        userFunctionsList.push(doc.data());
        //appState.userFunctionsList = (functions) => [...functions, doc.data()];
      });
      appState.userFunctionsList = userFunctionsList;
      loadDefaultFunc();
    });
}

export function updateTextEditor(func, functionIndex) {
  appState.currentFunctionIndex = functionIndex;
  appState.currentActiveFunction.sourceString = func.code;
  appState.currentActiveFunction.dependency = func.dependency;
  appState.currentActiveFunction.language = func.language;
  appState.currentActiveFunction.deployURL = func.deployURL;
  appState.currentActiveFunction.functionName = func.name;
  appState.isCreateNewFunctionClicked = false;
}

export async function loadUserFunctionsAndSetDefault() {
  await loadFunctions();
  appState.currentFunctionIndex = 0;
  updateTextEditor(appState.userFunctionsList[0], 0);
}

export async function getDeployedURLFromDocID(docID) {
  let deployedURL = null;
  const docRef = await firestore
    .collection('users')
    .doc(appState.userInfo.loggedInUser.uid)
    .collection('projects')
    .doc(appState.selectedProject)
    .collection('functions')
    .doc(docID);
  const doc = await docRef.get();
  if (doc.exists) {
    deployedURL = doc.data().deployURL;
  }
  return deployedURL;
}

export function mouseBusy(isTrue) {
  if (isTrue) {
    document.body.style.cursor = 'wait';
    document.getElementById('root').classList.add('cursorBusy');
  } else {
    document.body.style.cursor = '';
    document.getElementById('root').classList.remove('cursorBusy');
  }
}
