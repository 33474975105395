import { useState, useEffect } from 'react';
import Logo from '../LogosAndIcons/Logo';
import LoggedInUser from '../SignUp/LoggedInUser';
import { observer } from 'mobx-react';
import { LANGUAGES, MAX_ALLOWED_FUNCTIONS_FOR_FREE_USER } from '../../Constants';
import Help from './Help';
import { appState } from '../../modals/basemodal';
import { firestore } from '../../config/firebase';
import CodeExamples from '../LoggedInUserComponents/CodeSamples/CodeExamples';
import AcknowledgeModal from '../Modals/AcknowledgeModal';
import { loadNewFunction } from '../Utils/Utils';
import { PlusIcon } from '@heroicons/react/outline';


const LoggedInUserNavBar = observer(() => {

  const [displayLimitsModal, setDisplayLimitsModal] = useState(false);
  function createNew() {
    if( appState.userFunctionsList.length >= MAX_ALLOWED_FUNCTIONS_FOR_FREE_USER ) {
        setDisplayLimitsModal(true);
        return;
    }
    appState.codeString = CodeExamples[LANGUAGES.JAVASCRIPT];
    appState.language = LANGUAGES.JAVASCRIPT;
    appState.isCreateNewFunctionClicked = true;
    appState.currentFunctionIndex = -1;
    loadNewFunction();
  }

  function addProject(projectName) {
    var path = 'users/' + appState.userInfo.loggedInUser.uid + '/projects';
    firestore
      .collection('users')
      .doc(appState.userInfo.loggedInUser.uid)
      .collection('projects')
      .doc(projectName)
      .set({})
      .then((docRef) => {
        console.log('');
      })
      .catch((error) => {
        console.error('Error adding document: ', error);
      });
  }
  function hideMaxLimitDialog(){
    setDisplayLimitsModal(false);
  }

  return (
    <>
      <div className='absolute flex w-full bg-white h-14'>
        <div className='w-0 h-full my-2 lg:min-w-1/5 lg:w-1/5'>
          <Logo />
        </div>
        <div className='w-full h-full mt-0 leading-relaxed lg:mt-8 lg:w-3/5 lg:min-w-3/5'></div>
        <div className='flex justify-end w-0 h-full lg:min-w-1/5 lg:w-1/5'>
          <button className=' text-white text-base font-semibold bg-black hover:bg-amber-500 m-2 py-1 px-3 rounded-md' onClick={() => createNew()}>
            Create
            { /*<PlusIcon className="h-4 w-4" /> */ }
          </button>
          
          <Help />
          <LoggedInUser />
        </div>
      </div>
      {displayLimitsModal && (
        <AcknowledgeModal
          heading={'Function creation limit exceeded!'}
          content={
            'A free user can create maximum upto ' +
            MAX_ALLOWED_FUNCTIONS_FOR_FREE_USER +
            ' functions. Please consider upgrading to be able to create more number of functions.'
          }
          onOkClick={() => hideMaxLimitDialog()}
        />
      )}
    </>
  );
});

export default LoggedInUserNavBar;
